.app-label {
    color: #3f3f3f;
}

.app-link-container:hover {
    text-decoration: none;
}

.app-link-container {
    display: block;
    height: 100%;
}

.app-image-container {
    width: 100%;
    min-width: 70px;
    min-height: 120px;
    padding: 10px;
}

.app-label {
    text-align: center;
    font-weight: 600;
}
