.url-container, .url-container:hover {
    text-decoration: none;
    color: inherit;
}

.learning-guide-content-container {
    padding: 30px;
}

.category-selection-button {
    padding: 5px;
    cursor: pointer;
}

.category-selection-button:hover {
    color: white;
    background-color: #42a500;
}

.select-subject-container {
    text-align: right;
    margin-bottom: 10px;
}

.subject-selection-label {
    margin-right: 5px;
}

.content-wrapper {
    margin-bottom: 50px;
    border-radius: 5px;
    padding: 5px;
}

.content-wrapper:hover {
    background: #dadada;
    cursor: pointer;
}

.title {
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    text-decoration: none;
}

.thumbnail {
    display: none;
}

.url {
    margin: 0 0 10px;
    font-style: italic;
}

.curriculums-iframe-container {
    background-color: red;
}