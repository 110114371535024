.logo-container {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    margin: auto;
    min-width: 150px;
}

.nav-container {
    cursor: pointer;
    color: white;
    text-align: center;
    padding: 10px;
}

.nav-icon-container {
    width: 30px;
    display: inline-block;
}

.navigation-label {
    margin-left: 10px;
}

.label-description {
    font-size: 11px;
    color: #669a4d;
    margin-top: 10px;
    font-weight: 900;
}

.account-btn {
    font-weight: 700;
    cursor: pointer;
    font-size: 14px;
    margin: 5px 0;
}

.signup-modal-btn {
    background-color: #6eb44f;
    width: 100%;
    text-align: center;
    padding: 5px;
    border-radius: 15px;
    color: #ffffff;
    font-weight: 700;
    font-size: 15px;
    cursor: pointer;
}

.usertype-header {
    font-weight: 700;
    color: #6eb44f;
}

.usertype-list {
    padding-left: 20px;
    font-size: 15px;
}

.signup-modal.modal-dialog {
    min-width: 600px;
}

.signup-modal .modal-header {
    display: block;
    background: #669a4d;
    color: #ffffff;
    text-align: center;
}

.navigation-menu-image-container {
    width: 50px;
    margin-right: 5px;
}

.navigation-menu-image-container,
.navigation-menu-label {
    display: inline-block;
}

.nav-popover-container {
    min-width: 600px;
    padding: 20px;
    background: #fafff7;
}

.nav-label-submenu-description {
    font-size: 12px;
}
.nav-label-container {
    cursor: pointer;
    padding: 5px;
}

.nav-label-container:hover {
    background: #d3d3d3;
    cursor: pointer;
}

.category-select-container {
    margin: 10px 0;
    cursor: pointer;
    padding: 5px;
}

.category-select-container:hover {
    background-color: #6eb44f;
    color: #ffffff;
}
