.apps-group-label {
    color: #3f3f3f;
    font-weight: 700;
    font-size: 18px;
}

.search-field-container {
    margin-bottom: 30px;
    max-width: 700px;
}

.apps-group-container {
    margin: 30px auto 70px;
}

.divider {
    border-bottom: 1px solid black;
    margin: 20px 0;
}

.loading-container {
    height: 50vh;
    flex: 1;
    align-items: center;
    align-content: center;
    align-self: center;
}

.MuiCircularProgress-colorPrimary {
    color: #70b448 !important;
}
