.red {
    background-color: red;
}

.notebook-content-container {
    padding: 30px;
}

.notebook-subject-label {
    font-weight: 700;
    margin-bottom: 30px;
}

.notebook-content-wrapper {
    max-width: 900px;
    margin: auto;
    box-shadow: 0 0 5px 5px #dadada;
    padding: 10px;
}

.notebook-editor-container {
    margin: 20px 0;
}

.notebook-editor-wrapper {
    border: 1px solid lightgray;
    padding: 5px;
}

.notebook-editor-label {
    font-weight: bold;
    font-size: 1.17em;
}
