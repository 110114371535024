.log-in-container {
    padding: 5px;
}

.log-in-textfield-container {
    margin-bottom: 20px;
}

.log-in-textfield {
    width: 100%;
}

.log-in-button {
    width: 100%;
}