.content-information-container {
    max-width: 500px;
    margin: auto;
}

.content-infomation-wrapper {
    margin: 10px 0;
}

.content-infomation-label {
    display: inline;
    margin-right: 20px;
}

.content-information-select-button {
    width: 150px;
    text-align: center;
}

.content-information-textfield {
    width: 100%;
}

.google-slides-container {
    width: 100%;
    padding: 20px;
}







.stepper-button-container {
    text-align: center;
    margin: 20px 0;
}