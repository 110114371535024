.advance-learning-search-content-container {
    padding: 30px;
}

.search-bar-container {
    margin-bottom: 20px;
    max-width: 1000px;
}

.search-bar {
    width: 100%;
}

.search-content-wrapper {
    margin: 15px 0 20px;
    border-bottom: 1px solid #dadada;
    padding: 10px;
}

.search-content-wrapper:hover {
    background: #dadada;
    cursor: pointer;
}

.search-title {
    margin-top: 0;
    margin-bottom: 5px;
    font-weight: 700;
}

.search-link {
    color: #1a0dab;
    margin: -10px 0 5px 0 ;
    font-style: italic;
}